<template>
  <v-sheet   >

    <div v-if="!SM" class="fill-height">
      <iframe v-if="pdfUrl"  frameBorder="0"  class="pdfStyle" width="100%" :height="`${height}px`" :src="pdfUrl"></iframe>
    </div>

    <div v-else-if="pdfUrl && SM" :style="`height: ${height}px`"  class="overflow-y-auto">
      <pdf
          v-if="!pagesCount" :page="1" key="first"
          :src="pdfData"
          @num-pages="pagesCount = $event"
      />
      <template v-if="pagesCount && pdfUrl">
        <pdf v-for="page in pagesCount" :key="page"  :src="pdfData" :page="page " />
      </template>


    </div>


  </v-sheet>
</template>

<script>
import {mapActions} from "vuex";
import pdf from 'vue-pdf'

export default {
  name: "wsVideo",
  components : {
    pdf
  },
  props : {
    file : {
      type : Object,
      default() { return {} }
    },
    src : {
      type : String,
      default :''
    },
    courseId : {
      type : String
    },
    student : {
      type : Boolean
    },
    testId : {
      type : String,
    },
    fullScreen : {
      type : Boolean,
      default : false
    },
    isPublic : {
      type : Boolean,
      default : false,
    },
    height : {
      type : Number,
      default : 350
    },
    isCollapsed : {
      type : Boolean,
      default : false
    }
  },
  data() {
    return {
      uuid : '',
      url : null,
      pagesCount : null,
      loaded : false,
      pdfData : null
    }
  },
  computed : {
    pdfUrl() {
      if ( !this.url ) {
        return null
      }
      return this.url + '&noforce=true'
    }
  },
  watch: {
    file() {
      this.initFile()
    },
    SM() {
      if ( this.SM && this.url) {
        this.loadPdfData()
      }
    }
  },
  methods : {
    ...mapActions('upload', [ 'GET_PRIVATE_FILE', 'GET_PUBLIC_FILE', 'GET_STUDENT_COMPONENT_FILE', 'GET_STUDENT_TEST_FILE' ]),

    async loadPdfData() {
      const response = await fetch(this.pdfUrl, { responseType: 'arraybuffer' });
      const pdfData = await response.arrayBuffer();
      this.pdfData = new Uint8Array(pdfData)
    },

    async getFile( ) {

      if ( this.student ) {
        return this.openStudentFile(this.file)
      }

      let result = this.isPublic ? await this.GET_PUBLIC_FILE(this.file.uuid) :  await this.GET_PRIVATE_FILE(this.file.uuid)
      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url

      if ( this.SM) {
        this.loadPdfData()
      }
      
    },

    async openStudentFile(file) {



      let data =  null
      let result = null;
      if ( this.testId ) {
        data = {
          file : file.uuid,
          test : this.testId
        }
        result = await this.GET_STUDENT_TEST_FILE(data)
      } else {
        data = {
          file : file.uuid,
          course : this.courseId
        }
        result = await this.GET_STUDENT_COMPONENT_FILE(data)
      }

      if (!result) { return this.notify(this.$t('FileNotfound')) }

      this.url = result.url
      if ( this.SM) {
        this.loadPdfData()
      }
      
    },

    initFile() {
      if (this.file.uuid) {
        this.getFile()
      }
    }

  },
  mounted() {
    this.initFile()
  }
}
</script>

<style scoped>
.pdfStyle {
  border: none;
  padding: 0 !important;
  background-color: white;
}
</style>